<template>
  <div class="page-title">
    <div class="logo">
      <img :src="props.appicon" alt="" />
    </div>
    <div class="info">
      <h1>{{ props.appName }}</h1>
      <p class="message">{{ props.appName }} LTD CO.</p>
      <p class="desc">Verificado pelo aplicativo</p>
    </div>
  </div>
</template>
  <script>
import { reactive, toRefs, getCurrentInstance, defineComponent } from "vue";
export default defineComponent({
  props: {
    appicon: {
      type: String,
      default: "",
    },
    appName: {
      type: String,
      default: "",
    },
  },
  name: "",
  components: {},
  setup(props) {
    const state = reactive({});
    return { ...toRefs(state), props };
  },
});
</script>
  <style>
.logo {
  margin-right: 25px;
}
.logo img {
  width: 72px;
  height: 72px;
}
.message {
  color: #01875f;
  font-size: 16px;
}
</style>
  