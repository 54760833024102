<template>
  <div class="page-share">
    <div class="share-item" @click="bt">
      <span class="share-item-icon" style="position: relative">
        <svg width="24" height="24" viewBox="0 0 24 24" class="icon">
          <path
            d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"
          ></path>
        </svg>
      </span>
      <span class="share-item-message"> Compartilhar </span>
    </div>
    <div class="share-item" @click="bt">
      <span class="share-item-icon" style="position: relative">
        <svg width="24" height="24" viewBox="0 0 24 24" class="icon">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7 3H17C18.1045 3 19 3.8955 19 5V21L12 18L5 21L5.01075 5C5.01075 3.8955 5.8965 3 7 3ZM12 15.824L17 18V5H7V18L12 15.824ZM13 7V9H15V11H13V13H11V11H9V9H11V7H13Z"
          ></path>
        </svg>
      </span>
      <span class="share-item-message"> Adicionar à lista de desejos </span>
    </div>
  </div>
</template>
  <script>
import { reactive, toRefs, getCurrentInstance, defineComponent } from "vue";
export default defineComponent({
  name: "",
  components: {},
  props: {},
  setup(props, { emit }) {
    const state = reactive({});
    const bt = () => {
      emit("func");
    };
    return { ...toRefs(state), bt };
  },
});
</script>
  <style>
.page-share {
  justify-content: space-between;
}

.share-item {
  font-size: 16px;
  color: rgb(1, 135, 95);
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.icon {
  color: rgb(1, 135, 95);
  fill: #01875f;
  stop-color: #01875f;
}

.share-item-message {
  margin-left: 5px;
}

.share-item-icon {
  height: 22px;
}
</style>
  