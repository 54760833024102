<template>
  <div class="page-other-message">
    <section>
      <p>Vales-presente</p>
      <p>Resgatar</p>
      <p>Política de reembolso</p>
    </section>
    <br />
    <br />
    <section>
      <p style="font-weight: 500">Crianças e família</p>
      <p>Guia para a família</p>
      <p>Compartilhamento em família</p>
    </section>
    <br />
    <br />
    <div class="mess-item">
      <span>Termos de Serviço</span>
      <span>Privacidade</span>
      <span>Desenvolvedores</span>
    </div>
    <br />
    <p>Todos os preços incluem Tributo.</p>
    <br />
    <p class="gj">
      <img src="../assets/brazil.png" alt="" />
      <span>Brasil (Português)</span>
    </p>
    <br />
  </div>
</template>
  <script>
import { reactive, toRefs, getCurrentInstance, defineComponent } from "vue";
export default defineComponent({
  name: "",
  components: {},
  setup() {
    const state = reactive({});
    return { ...toRefs(state) };
  },
});
</script>
  <style>
.page-other-message {
  padding-top: 32px;
  flex-direction: column;
  color: rgb(95, 99, 104);
}
</style>
  