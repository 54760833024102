<template>
  

  <div class="page-info">
        <div class="page-info-title">
          <span class="label">Sobre este jogo</span>
          <span  @click="bt" class="icon"><img src="../assets/right.svg" alt="" /></span>
        </div>
        <div class="page-info-text">
          Sobre este jogo *O jogo de slots de cassino mais quente de 2024 * O
          som dos rolos, as luzes na tela e a emoção da vitória! Os Casino Slots
          trazem o espetáculo das slots de cassino de Las Vegas e Macau para a
          ponta dos seus dedos! Também traz os sons e visuais da experiência do
          cassino para casa e empilha seus ganhos até o céu! Quanto mais você
          girar, mais você ganha!
        </div>
      </div>
      <div class="page-Am">
        <div class="page-Am-title">Atualizado em</div>
        <div class="page-Am-desc">6 de nov. de 2024</div>
      </div>
      <div class="page-info">
        <div class="page-info-title">
          <span class="label">Segurança dos dados</span>
          <span  @click="bt" class="icon"><img src="../assets/right.svg" alt="" /></span>
        </div>
        <div class="page-info-text" style="
            letter-spacing: 0.5px;
            font-family: Roboto, Helvetica, Arial, sans-serif;
            line-height: 24px;
          ">
          Sua segurança começa com o entendimento de como os desenvolvedores
          coletam e compartilham seus dados. As práticas de segurança e
          privacidade de dados podem variar de acordo com o uso, a região e a
          idade. O desenvolvedor forneceu as informações a seguir, que podem ser
          atualizadas ao longo do tempo.
        </div>
      </div>
      <div class="page-table">
        <div class="page-table-item">
          <span class="icon"><img src="../assets/share.webp" alt="" /></span>
          <span class="text">Os dados não são compartilhados com terceiros &nbsp; Saiba mais
            sobre como os desenvolvedores declaram o compartilhamento
          </span>
        </div>
        <div class="page-table-item">
          <span class="icon"><img src="../assets/yun.webp" alt="" /></span>
          <span class="text">Este app pode coletar estes tipos de dados &nbsp; Local, Atividade
            no app e Identificadores do dispositivo e outros
          </span>
        </div>
        <div class="page-table-item">
          <span class="icon"><img src="../assets/lock.webp" alt="" /></span>
          <span class="text">Os dados são criptografados em trânsito </span>
        </div>
        <div class="page-table-item">
          <span class="icon"><img src="../assets/delete.webp" alt="" /></span>
          <span class="text">Você pode solicitar a exclusão dos dados </span>
        </div>
        <p class="table-bottom">Mais detalhes</p>
      </div>
      <div class="page-info">
        <div class="page-info-title">
          <span class="label">Classificações e resenhas</span>
          <span  @click="bt" class="icon"><img src="../assets/right.svg" alt="" /></span>
        </div>
        <div @click="bt" class="page-info-text cent" style="padding-top: 0">
          As notas e avaliações são verificadas
          <img src="../assets/info.png" alt="" />
        </div>
      </div>
      <div class="page-tags">
        <div @click="bt" class="tag-item active">
          <span class="icon"><svg t="1726828048865" class="icon" viewBox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="5540" id="mx_n_1726828048866" width="200" height="200">
              <path
                d="M823.00322 24.512277l-591.737042 0c-11.307533 0-20.466124 9.15859-20.466124 20.466124l0 934.043199c0 11.2973 9.15859 20.466124 20.466124 20.466124l591.737042 0c11.307533 0 20.466124-9.168824 20.466124-20.466124L843.469344 44.978401C843.469344 33.670867 834.310753 24.512277 823.00322 24.512277zM802.537096 773.96127l-480.135268 0c-11.307533 0-20.466124 9.168824-20.466124 20.466124 0 11.307533 9.15859 20.466124 20.466124 20.466124l480.135268 0 0 143.661957-550.804794 0L251.732301 65.444525l550.804794 0L802.537096 773.96127z"
                p-id="5541" fill="#056449"></path>
              <path
                d="M527.134699 886.514719m-48.461735 0a47.358 47.358 0 1 0 96.92347 0 47.358 47.358 0 1 0-96.92347 0Z"
                p-id="5542" fill="#056449"></path>
            </svg></span>

          <span @click="bt">Telefone</span>
        </div>
        <div class="tag-item">
          <span class="icon"><svg t="1726828385801" class="icon" viewBox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="6873" id="mx_n_1726828385801" width="200" height="200">
              <path d="M960 0H128v1024h832V0zM192 768V64h704v704H192z m320 128h64v-64h320v128H192v-128h320v64z"
                fill="#5F6368" p-id="6874"></path>
            </svg></span>
          <span>Tablet</span>
        </div>
      </div>
      <div class="page-score">
        <div class="score-details">
          <span class="Number">4,9</span>
          <div class="star-list">
            <span class="star"></span>
            <span class="star"></span>
            <span class="star"></span>
            <span class="star"></span>
            <span class="star"></span>
          </div>
          <p class="score-desc">1,91 mil avaliações</p>
        </div>
        <div class="score-pro">
          <div class="pro-item">
            <span class="label">5</span>
            <span class="pro-bg">
              <span class="pro-active" style="width: 90.644%"></span>
            </span>
          </div>
          <div class="pro-item">
            <span class="label">4</span>
            <span class="pro-bg">
              <span class="pro-active" style="width: 6.54%"></span>
            </span>
          </div>
          <div class="pro-item">
            <span class="label">3</span>
            <span class="pro-bg">
              <span class="pro-active" style="width: 2.11%"></span>
            </span>
          </div>
          <div class="pro-item">
            <span class="label">2</span>
            <span class="pro-bg">
              <span class="pro-active" style="width: 1.44%"></span>
            </span>
          </div>
          <div class="pro-item">
            <span class="label">1</span>
            <span class="pro-bg">
              <span class="pro-active" style="width: 1.2%"></span>
            </span>
          </div>
        </div>
      </div>
      <div class="page-comment">
        <div class="comment-title">
          <div class="user-info" >
            <span class="avatar"  @click="bt"><img src="../assets/avatar1.webp" alt="" /></span>
            <span class="name">Lara Liras</span>
          </div>
          <span  @click="bt" class="menu"><svg t="1726830269279" class="icon" viewBox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="8713">
              <path
                d="M644.87866595 886.42622805c0-71.30375737-56.36701639-129.10635008-125.88707384-129.10635008-69.51074361 0-125.89755278 57.80259271-125.89755279 129.10635008 0 71.32238621 56.38797312 129.12381383 125.89755279 129.12381384C588.51281351 1015.55004189 644.87866595 957.74745031 644.87866595 886.42622805zM644.87866595 499.08971405c0-71.30259342-56.36701639-129.12381383-125.88707384-129.12381382-69.51074361 0-125.89755278 57.82238549-125.89755278 129.12381383 0 71.30375737 56.38797312 129.10635008 125.89755278 129.10635008C588.51281351 628.19606414 644.87866595 570.39347142 644.87866595 499.08971405zM644.87866595 137.57493589c0-71.32355015-56.36701639-129.12497891-125.88707384-129.12497891-69.51074361 0-125.89755278 57.80142762-125.89755279 129.12497891 0 71.30375737 56.38797312 129.10518499 125.89755279 129.105185C588.51281351 266.67895694 644.87866595 208.87752931 644.87866595 137.57493589z"
                p-id="8714" fill="#5F6368"></path>
            </svg></span>
        </div>
        <div class="comment-date">
          <div class="star-list">
            <span class="star" style="width: 10px; height: 10px; margin-right: 2px"></span>
            <span class="star" style="width: 10px; height: 10px; margin-right: 2px"></span>
            <span class="star" style="width: 10px; height: 10px; margin-right: 2px"></span>
            <span class="star" style="width: 10px; height: 10px; margin-right: 2px"></span>
            <span class="star" style="width: 10px; height: 10px; margin-right: 2px"></span>
          </div>
          <p class="date">5 de setembro de 2024</p>
        </div>
        <div class="comment-content">
          O jogo é bom , mais precisa de um incentivo, no geral eu gostei
          bastante Dá pra pessoa distrair e jogar é muito saudável ! Amei os
          porquinhos kkk
        </div>
        <div class="comment-footer">
          <p>Essa avaliação foi marcada como útil por 52 pessoas</p>
          <p class="answer">
            <span class="answer-text">Você achou isso útil?</span>
            <span class="answer-btn"  @click="bt">Sim</span>
            <span class="answer-btn"  @click="bt">Não</span>
          </p>
        </div>
      </div>
      <div class="page-comment">
        <div class="comment-title">
          <div class="user-info"  @click="bt">
            <span class="avatar"><img src="../assets/avatar2.webp" alt="" /></span>
            <span class="name">Angelita Da Silva</span>
          </div>
          <span  @click="bt" class="menu"><svg t="1726830269279" class="icon" viewBox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="8713">
              <path
                d="M644.87866595 886.42622805c0-71.30375737-56.36701639-129.10635008-125.88707384-129.10635008-69.51074361 0-125.89755278 57.80259271-125.89755279 129.10635008 0 71.32238621 56.38797312 129.12381383 125.89755279 129.12381384C588.51281351 1015.55004189 644.87866595 957.74745031 644.87866595 886.42622805zM644.87866595 499.08971405c0-71.30259342-56.36701639-129.12381383-125.88707384-129.12381382-69.51074361 0-125.89755278 57.82238549-125.89755278 129.12381383 0 71.30375737 56.38797312 129.10635008 125.89755278 129.10635008C588.51281351 628.19606414 644.87866595 570.39347142 644.87866595 499.08971405zM644.87866595 137.57493589c0-71.32355015-56.36701639-129.12497891-125.88707384-129.12497891-69.51074361 0-125.89755278 57.80142762-125.89755279 129.12497891 0 71.30375737 56.38797312 129.10518499 125.89755279 129.105185C588.51281351 266.67895694 644.87866595 208.87752931 644.87866595 137.57493589z"
                p-id="8714" fill="#5F6368"></path>
            </svg></span>
        </div>
        <div class="comment-date">
          <div class="star-list">
            <span class="star" style="width: 10px; height: 10px; margin-right: 2px"></span>
            <span class="star" style="width: 10px; height: 10px; margin-right: 2px"></span>
            <span class="star" style="width: 10px; height: 10px; margin-right: 2px"></span>
            <span class="star" style="width: 10px; height: 10px; margin-right: 2px"></span>
            <span class="star" style="width: 10px; height: 10px; margin-right: 2px"></span>
          </div>
          <p class="date">19 de agosto de 2024</p>
        </div>
        <div class="comment-content">
          Gosto muito, o jogo é super divertido e o jackpot explode todos os
          dias
        </div>
        <div class="comment-footer">
          <p>Essa avaliação foi marcada como útil por 22 pessoas</p>
          <p class="answer">
            <span class="answer-text">Você achou isso útil?</span>
            <span class="answer-btn"  @click="bt">Sim</span>
            <span class="answer-btn"  @click="bt">Não</span>
          </p>
        </div>
      </div>
      <div class="page-comment">
        <div class="comment-title">
          <div class="user-info"  @click="bt">
            <span class="avatar"><img src="../assets/avatar3.webp" alt="" /></span>
            <span class="name">Rubens Vieira dos Santos</span>
          </div>
          <span  @click="bt" class="menu"><svg t="1726830269279" class="icon" viewBox="0 0 1024 1024" version="1.1"
              xmlns="http://www.w3.org/2000/svg" p-id="8713">
              <path
                d="M644.87866595 886.42622805c0-71.30375737-56.36701639-129.10635008-125.88707384-129.10635008-69.51074361 0-125.89755278 57.80259271-125.89755279 129.10635008 0 71.32238621 56.38797312 129.12381383 125.89755279 129.12381384C588.51281351 1015.55004189 644.87866595 957.74745031 644.87866595 886.42622805zM644.87866595 499.08971405c0-71.30259342-56.36701639-129.12381383-125.88707384-129.12381382-69.51074361 0-125.89755278 57.82238549-125.89755278 129.12381383 0 71.30375737 56.38797312 129.10635008 125.89755278 129.10635008C588.51281351 628.19606414 644.87866595 570.39347142 644.87866595 499.08971405zM644.87866595 137.57493589c0-71.32355015-56.36701639-129.12497891-125.88707384-129.12497891-69.51074361 0-125.89755278 57.80142762-125.89755279 129.12497891 0 71.30375737 56.38797312 129.10518499 125.89755279 129.105185C588.51281351 266.67895694 644.87866595 208.87752931 644.87866595 137.57493589z"
                p-id="8714" fill="#5F6368"></path>
            </svg></span>
        </div>
        <div class="comment-date">
          <div class="star-list">
            <span class="star" style="width: 10px; height: 10px; margin-right: 2px"></span>
            <span class="star" style="width: 10px; height: 10px; margin-right: 2px"></span>
            <span class="star" style="width: 10px; height: 10px; margin-right: 2px"></span>
            <span class="star" style="width: 10px; height: 10px; margin-right: 2px"></span>
            <span class="star" style="width: 10px; height: 10px; margin-right: 2px"></span>
          </div>
          <p class="date">9 de junho de 2024</p>
        </div>
        <div class="comment-content">
          O jogo é bom , mais precisa de um incentivo, no geral eu gostei
          bastante Dá pra pessoa distrair e jogar é muito saudável ! Amei os
          porquinhos kkk
        </div>
        <div class="comment-footer">
          <p>Essa avaliação foi marcada como útil por 18 pessoas</p>
          <p class="answer">
            <span class="answer-text">Você achou isso útil?</span>
            <span class="answer-btn"  @click="bt">Sim</span>
            <span class="answer-btn"  @click="bt">Não</span>
          </p>
        </div>
      </div>
      <div class="page-comment-link">Ver todas as avaliações</div>
  </template>
  <script>
import { reactive, toRefs, getCurrentInstance, defineComponent } from "vue";
export default defineComponent({
  name: "",
  components: {},
  props: {},
  setup(props, { emit }) {
    const state = reactive({});
    const bt = () => {
      emit("func");
    };
    return { ...toRefs(state), bt };
  },
});
  </script>
  <style></style>
  